import React, {Component} from "react";
import { Helmet } from "react-helmet";
import he from "he";
import settings from "../../../.brew-cache/settings.json";
import Apple57 from "../../images/favicon/apple-icon-57x57.png";
import Apple60 from "../../images/favicon/apple-icon-60x60.png";
import Apple72 from "../../images/favicon/apple-icon-72x72.png";
import Apple76 from "../../images/favicon/apple-icon-76x76.png";
import Apple114 from "../../images/favicon/apple-icon-114x114.png";
import Apple120 from "../../images/favicon/apple-icon-120x120.png";
import Apple144 from "../../images/favicon/apple-icon-144x144.png";
import Apple152 from "../../images/favicon/apple-icon-152x152.png";
import Apple180 from "../../images/favicon/apple-icon-180x180.png";
import Android190 from "../../images/favicon/android-icon-192x192.png";
import Fav32 from "../../images/favicon/favicon-32x32.png";
import Fav96 from "../../images/favicon/favicon-96x96.png";
import Fav16 from "../../images/favicon/favicon-16x16.png";
import Ms144 from "../../images/favicon/ms-icon-144x144.png";

class Meta extends Component {

    getSchema(){
        const schemaOrgJSONLD = [];

        // Add any custom schema objects being passed through from the page
        if (this.props.meta.schema) {
            this.props.meta.schema.map(item => {
                return schemaOrgJSONLD.push(item);
            });
        }
        return JSON.stringify(schemaOrgJSONLD);
    }
    
    render() {
        const meta = this.props.meta;
        const siteURL = process.env.GATSBY_FRONTEND_URL;
        const siteName = settings.clientOptions.siteName;
        const defaultImage = settings.themeOptions.ThemeOptions.defaultImages.schemaAndShareImage.sourceUrl;

        return (
            <Helmet
                encodeSpecialCharacters={false}
                htmlAttributes={{ lang : meta.locale ? meta.locale : 'en' }}
            >
                <title>{meta.metaTitle ? he.decode(meta.metaTitle) : this.props.title}</title>
                <html lang="en" />
                <meta name="description" content={meta.metaDescription} />

                { meta.canonical !== undefined  ?  <link rel="canonical" href={this.siteURL + '/' + meta.canonical} /> : null }

                <meta name="docsearch:version" content="2.0" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                />

                {/* Schema */}
                <script type="application/ld+json">
                    {this.getSchema()}
                </script>

                {/* OpenGraph */}
                { meta.ogTitle ? <meta property="og:url" content={siteURL + this.props.path} /> : null }
                { meta.ogTitle ? <meta property="og:type" content="website" /> : null }
                { meta.ogTitle ? <meta property="og:locale" content="en" /> : null }
                { meta.ogTitle ? <meta property="og:site_name" content={siteName} /> : null }
                { meta.ogTitle ? <meta property="og:title" content={meta.ogTitle} /> : null }
                { meta.ogDescription  ? <meta property="og:description" content={meta.ogDescription} /> : null }
                { meta.ogImage  ? <meta property="og:image" content={siteURL + meta.ogImage} /> : <meta property="og:image" content={defaultImage} /> }

                {/* Twitter Card */}
                { meta.twitterTitle ? <meta name="twitter:site" content={settings.themeOptions.ThemeOptions.twitterHandle} /> : null }
                { meta.twitterTitle ? <meta name="twitter:title" content={meta.twitterTitle} /> : null }
                { meta.twitterTitle ? <meta name="twitter:description" content={meta.twitterDescription} /> : null }
                { meta.twitterTitle ? <meta name="twitter:card" content={meta.twitterCardType} /> : null }
                { meta.twitterImage ? <meta name="twitter:image" content={siteURL + meta.twitterImage} /> : <meta name="twitter:image" content={defaultImage} /> }

                <link rel="apple-touch-icon" sizes="57x57" href={Apple57 } />
                <link rel="apple-touch-icon" sizes="60x60" href={Apple60} />
                <link rel="apple-touch-icon" sizes="72x72" href={Apple72} />
                <link rel="apple-touch-icon" sizes="76x76" href={Apple76} />
                <link rel="apple-touch-icon" sizes="114x114" href={Apple114} />
                <link rel="apple-touch-icon" sizes="120x120" href={Apple120} />
                <link rel="apple-touch-icon" sizes="144x144" href={Apple144} />
                <link rel="apple-touch-icon" sizes="152x152" href={Apple152} />
                <link rel="apple-touch-icon" sizes="180x180" href={Apple180} />
                <link rel="icon" type="image/png" sizes="192x192"  href={Android190} />
                <link rel="icon" type="image/png" sizes="32x32" href={Fav16} />
                <link rel="icon" type="image/png" sizes="96x96" href={Fav96} />
                <link rel="icon" type="image/png" sizes="16x16" href={Fav32} />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content={Ms144} />
                <meta name="theme-color" content="#ffffff" />

                <div id="fb-root"></div>
                <script crossorigin="anonymous"
                src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v13.0" nonce="wmX0jTyr"></script>

            </Helmet>
        )
    }
}

export default Meta
