const styles = theme => ({
  aagFooter: {
    backgroundColor: "#D0CFD2",
    borderTop: "4px solid " + theme.palette.background.default,
    "& .wrapper": {
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.up(435)]: {
        justifyContent: "flex-start",
        padding: 0,
      },
      "& .ga-logo": {
        flex: "0 0 245px",
        maxWidth: 245,
        marginBottom: 10,
        margin: 12,
      },
      "& .ga-member-logo": {
        flex: "0 0 275px",
        maxWidth: 275,
        margin: 12,
      },
    },
  },
})
export default styles
