const styles = theme => ({
  footer001: {
    backgroundColor: theme.palette.primary.main,
    padding: "40px 0",
    [theme.breakpoints.up("lg")]: {
      paddingTop: 100,
    },
    "& p, & a": {
      color: theme.palette.primary.contrastText,
      fontSize: 16,
      lineHeight: 1.5,
      marginBottom: 24,
      fontFamily: theme.bodyFont,
      marginTop: 0,
    },
    "& a": {
      transition: "opacity .3s ease-in-out",
      "&:hover, &:focus": {
        opacity: 0.8,
      },
    },
    "& .site-logo": {
      textAlign: "center",
      flex: "0 0 100%",
      padding: "0 12px",
      marginBottom: 40,
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 25%",
        textAlign: "left",
        maxWidth: "25%",
      },
    },
    "& .footer-columns": {
      display: "flex",
      flex: "0 0 100%",
      flexWrap: "wrap",
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 50%",
        maxWidth: "50%",
      },
      "& .col": {
        flex: "0 0 100%",
        padding: "0 12px",
        marginBottom: 32,
        "& .footer-h": {
          fontFamily: theme.headerFont,
          marginBottom: 40,
          textTransform: "uppercase",
          padding: "0 0 6px 0",
          borderBottom: "1px solid " + theme.palette.primary.contrastText,
        },
        "& a": {
          display: "block",
          wordWrap: "break-word",
        },
        [theme.breakpoints.up("sm")]: {
          "&.contact, &.nav": {
            flex: "0 0 50%",
            maxWidth: "50%",
          },
        },
        [theme.breakpoints.up("md")]: {
          "&.contact, &.nav, &.social": {
            flex: "0 0 33.33%",
            maxWidth: "33.333%",
          },
        },
        "&.social": {
          "& a": {
            [theme.breakpoints.up("sm")]: {
              display: "inline-block",
              width: "50%",
            },
            [theme.breakpoints.up("md")]: {
              display: "block",
              width: "auto",
              maxWidth: "none",
            },
            "& svg": {
              width: 18,
              height: 18,
              verticalAlign: "middle",
              marginRight: 24,
            },
            "& span": {
              lineHeight: "18px",
              display: "inline-block",
              verticalAlign: "middle",
            },
          },
        },
      },
    },
    "& .strapline": {
      fontFamily: theme.headerFont,
      direction: "rtl",
      textAlign: "center",
      flex: "0 0 100%",
      fontSize: 24,
      lineHeight: 1.33,
      fontWeight: "bold",
      padding: "0 12px",
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 25%",
        maxWidth: "25%",
        textAlign: "right",
        fontSize: 48,
        lineHeight: 1.05,
      },
    },
    "& .footer-bottom": {
      flex: "0 0 calc(100% - 24px)",
      margin: "0 12px",
      marginTop: 40,
      paddingTop: 40,
      borderTop: "1px solid " + theme.palette.primary.contrastText,
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: 120,
      },
      "& p": {
        textAlign: "center",
        "&.copyright": {
          [theme.breakpoints.up("md")]: {
            textAlign: "left",
            flex: "0 0 50%",
            maxWidth: "50%",
          },
        },
        "&.credit": {
          "& a": {
            color: "#6aa43b !important",
            fontWeight: "bold",
          },
          [theme.breakpoints.up("md")]: {
            textAlign: "right",
            flex: "0 0 50%",
            maxWidth: "50%",
          },
        },
      },
    },
  },
})
export default styles
