export default theme => ({ // eslint-disable-line
  header001: {
    backgroundColor: theme.palette.primary.main,
    padding: "24px 0",
    "& .wrapper": {
      justifyContent: "space-between",
      [theme.breakpoints.up("md")]: {
        alignItems: "flex-end",
      },
      "& .trade-login": {
        textAlign: "right",
        flex: "0 0 calc(100% - 24px)",
        margin: "-24px 12px 12px",
        [theme.breakpoints.up("md")]: {
          marginBottom: -12,
        },
        "& .button": {
          margin: "0",
          fontSize: 16,
          textTransform: "uppercase",
          padding: "6px 14px",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          marginTop: "-" + theme.border.thickness,
        },
      },
    },
    "& .site-logo": {
      flex: "0 0 50%",
      padding: "0 12px",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 235px",
      },
      "& a": {
        fontSize: 0,
        padding: 0,
        display: "block",
      },
    },
    "& .menu-trigger": {
      color: theme.palette.secondary.contrastText,
      fontSize: 12,
      lineHeight: "20px",
      padding: 0,
      backgroundColor: "transparent",
      border: "none",
      fontFamily: theme.headerFont,
      textTransform: "uppercase",
      fontWeight: "bold",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      "&::after": {
        content: '"Menu"',
      },
      "& span": {
        display: "block",
        position: "relative",
        height: 3,
        backgroundColor: theme.palette.secondary.contrastText,
        borderRadius: 2,
        width: 25,
        margin: "12px 10px",
        transition: "width 250ms ease-in-out, margin-left 250ms ease-in-out",
        [theme.breakpoints.up("lg")]: {
          transform: "scale(0.6)",
          margin: "-4px 0 0 14px",
        },
        "&::after, &::before": {
          content: '""',
          backgroundColor: theme.palette.secondary.contrastText,
          position: "absolute",
          height: 3,
          borderRadius: 2,
          transition: "width 250ms ease-in-out",
          right: 0,
        },
        "&::after": {
          width: 25,
          top: 7,
        },
        "&::before": {
          width: 25,
          bottom: 7,
        },
      },
      "&.primary": {
        color: theme.palette.primary.main,
        "& span, & span::before, & span::after": {
          backgroundColor: theme.palette.primary.main,
        },
      },
      "&.primary-variant": {
        color: theme.palette.primary.dark,
        "& span, & span::before, & span::after": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
      "&.on-primary": {
        color: theme.palette.primary.contrastText,
        "& span, & span::before, & span::after": {
          backgroundColor: theme.palette.primary.contrastText,
        },
      },
      "&.secondary": {
        color: theme.palette.secondary.main,
        "& span, & span::before, & span::after": {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      "&.secondary-variant": {
        color: theme.palette.secondary.dark,
        "& span, & span::before, & span::after": {
          backgroundColor: theme.palette.secondary.dark,
        },
      },
      "&.on-secondary": {
        color: theme.palette.secondary.contrastText,
        "& span, & span::before, & span::after": {
          backgroundColor: theme.palette.secondary.contrastText,
        },
      },
      "&.highlight": {
        color: theme.palette.tertiary.main,
        "& span, & span::before, & span::after": {
          backgroundColor: theme.palette.tertiary.main,
        },
      },
      "&.highlight-variant": {
        color: theme.palette.tertiary.dark,
        "& span, & span::before, & span::after": {
          backgroundColor: theme.palette.tertiary.dark,
        },
      },
      "&.on-highlight": {
        color: theme.palette.tertiary.contrastText,
        "& span, & span::before, & span::after": {
          backgroundColor: theme.palette.tertiary.contrastText,
        },
      },
      "&.background": {
        color: theme.palette.background.main,
        "& span, & span::before, & span::after": {
          backgroundColor: theme.palette.background.main,
        },
      },
      "&.background-variant": {
        color: theme.palette.background.paper,
        "& span, & span::before, & span::after": {
          backgroundColor: theme.palette.background.paper,
        },
      },
      "&.font-colour": {
        color: theme.palette.common.black,
        "& span, & span::before, & span::after": {
          backgroundColor: theme.palette.common.black,
        },
      },
    },
    "& .site-nav": {
      flex: "0 0 100%",
      padding: "0 12px",
      marginBottom: "-12px",
      opacity: 0,
      height: 0,
      overflow: "hidden",
      transition: "opacity .3s ease-in-out",
      [theme.breakpoints.up("md")]: {
        textAlign: "right",
        flex: "0 0 calc(100% - 250px)",
        opacity: 1,
        height: "auto",
        marginBottom: 0,
        overflow: "visible",
      },
      "& > a, & button": {
        fontFamily: theme.headerFont,
        color: theme.palette.primary.contrastText,
        fontWeight: "bold",
        fontSize: 18,
        padding: "12px 0",
        textTransform: "uppercase",
        display: "block",
        transition: "opacity .3s ease-in-out",
        border: "none",
        backgroundColor: "transparent",
        "&:hover, &:focus": {
          opacity: 0.8,
        },
        [theme.breakpoints.up("md")]: {
          marginLeft: 32,
          display: "inline-block",
          padding: "8px 0",
        },

        "& svg": {
          display: "inline-block",
          verticalAlign: "bottom",
        },
      },
      "& button": {
        marginLeft: "0 !important",
      },
      "& div.sub-menu": {
        position: "relative",
        [theme.breakpoints.up("md")]: {
          marginLeft: 32,
          display: "inline-block",
          padding: "8px 0",
        },

        "& .sub-items-wrap": {
          backgroundColor: theme.palette.primary.contrastText,
          height: 0,
          opacity: 0,
          transition: "opacity .3s ease-in-out",
          overflow: "hidden",
          [theme.breakpoints.up("md")]: {
            position: "absolute",
            left: "50%",
            top: "calc(100% + 24px)",
            transform: "translateX(-50%)",
            borderBottomLeftRadius: theme.border.radius,
            borderBottomRightRadius: theme.border.radius,
            boxShadow: theme.shadow,
            zIndex: 10,
          },
          "& .sub-items": {
            textAlign: "left",
            padding: "24px 12px",
            borderTop: "4px solid " + theme.palette.accent.main,
            "& > a": {
              fontFamily: theme.headerFont,
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontSize: 16,
              padding: "8px 12px",
              display: "block",
              transition: "opacity .3s ease-in-out",
              border: "none",
              backgroundColor: "transparent",
              [theme.breakpoints.up("md")]: {
                whiteSpace: "nowrap",
              },
              "&:hover, &:focus": {
                opacity: 0.8,
              },
            },
          },
        },
        '&[data-active="true"]': {
          "& .sub-items-wrap": {
            height: "auto",
            opacity: 1,
          },
        },
      },
    },
    "&[open]": {
      "& .menu-trigger": {
        "&::after": {
          content: '"Close"',
        },
      },
      "& .site-nav": {
        height: "auto",
        opacity: 1,
      },
    },
  },
})
