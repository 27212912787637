import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql, navigate } from "gatsby"
import Backdrop from "@material-ui/core/Backdrop/Backdrop"
// import LighteningIcon from "@material-ui/icons/FlashOn"
import Modal from "@material-ui/core/Modal/Modal"
import ReactHtmlParser, { processNodes } from "react-html-parser"
import Link from "../Link"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export default function GlobalAlert() {
  const classes = useStyles()

  const [open, setOpen] = React.useState(true)

  const data = useStaticQuery(graphql`
    query globalAlert {
      wp {
        alertBox {
          AlertBox {
            fieldGroupName
            showAlert
            alertContent
          }
        }
        themeOptions {
          ThemeOptions {
            alertBox {
              backgroundColour
              bullhornIconColour
              textColour
            }
          }
        }
      }
    }
  `)

  const overrides = data.wp.themeOptions.ThemeOptions.alertBox

  const isBrowser = typeof window !== `undefined`

  const hasSeen = () => {
    if (isBrowser) {
      return window.sessionStorage.getItem("hasSeenGlobalAlert")
    } else {
      return true
    }
  }

  const handleClose = () => {
    setOpen(false)

    if (isBrowser) {
      window.sessionStorage.setItem("hasSeenGlobalAlert", true)
    }
  }

  const closeLink = (e, link) => {
    e.preventDefault()
    handleClose()
    navigate(link)
  }

  const modalCloseLink = node => {
    // Convert a tags to links that handle routing
    if (node.type === "tag" && node.name === "a") {
      // Replace the admin URL in the link if set
      const link = node.attribs.href
      return (
        <Link key={link} to={link} onClick={e => closeLink(e, link)}>
          {processNodes(node.children, modalCloseLink)}
        </Link>
      )
    }
  }

  if (hasSeen() === null && data.wp.alertBox.AlertBox.showAlert === true) {
    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.alertModal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className="alert-modal"
          data-override-background={
            overrides.backgroundColour !== "secondary"
              ? overrides.backgroundColour
              : null
          }
          data-override-color={
            overrides.textColour !== "on-secondary"
              ? overrides.textColour
              : "on-secondary"
          }
        >
          <div
            className={`bullhorn ${
              overrides.bullhornIconColour !== "primary"
                ? overrides.bullhornIconColour
                : "primary"
            }`}
          >
            <svg
              viewBox="0 0 24 24"
              focusable="false"
              aria-hidden="true"
              data-override-fill={
                overrides.bullhornIconColour !== "primary"
                  ? overrides.bullhornIconColour
                  : "primary"
              }
            >
              <path d="M18 11v2h4v-2h-4zm-2 6.61c.96.71 2.21 1.65 3.2 2.39.4-.53.8-1.07 1.2-1.6-.99-.74-2.24-1.68-3.2-2.4-.4.54-.8 1.08-1.2 1.61zM20.4 5.6c-.4-.53-.8-1.07-1.2-1.6-.99.74-2.24 1.68-3.2 2.4.4.53.8 1.07 1.2 1.6.96-.72 2.21-1.65 3.2-2.4zM4 9c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h1v4h2v-4h1l5 3V6L8 9H4zm11.5 3c0-1.33-.58-2.53-1.5-3.35v6.69c.92-.81 1.5-2.01 1.5-3.34z"></path>
            </svg>
          </div>
          {ReactHtmlParser(data.wp.alertBox.AlertBox.alertContent, {
            transform: modalCloseLink,
          })}
          <button type="button" onClick={handleClose}>
            Close <span />
          </button>
        </div>
      </Modal>
    )
  } else {
    return null
  }
}
