export default theme => ({ // eslint-disable-line
    button: {
        fontSize: 18,
        lineHeight: 1.5,
        fontFamily: theme.subHeaderFont,
        fontWeight: 'bold',
        display: 'inline-block',
        marginLeft: 12,
        marginBottom: 16,
        maxWidth: 1118,
        padding: '8px 24px',
        position: 'relative',
        [theme.breakpoints.up(1118)]: {
            marginLeft: 'calc(50% - 559px)',
        },
        '& span': {
            zIndex: 1,
            position: 'relative',
        },
        '& span.material-icons': {
            width: 14,
            height: 14,
            fontSize: 14,
        },
        '&.primary': {
            color: theme.palette.accent.contrastText,
            backgroundColor: theme.palette.accent.main,
            borderWidth: theme.border.thickness,
            borderRadius: theme.border.radius,
            borderColor: theme.palette.accent.main,
            border: 'solid ' + theme.palette.accent.main,
            transition: 'background-color .3s ease-in-out, color .3s ease-in-out',
            boxShadow: theme.shadow,
            '&:hover, &:focus': {
                outline: 'none',
                backgroundColor: theme.palette.accent.contrastText,
                color: theme.palette.accent.main,
            }
        },
        '&.secondary': {
            color: theme.palette.accent.main,
            transition: 'color .3s ease-in-out',
            paddingLeft: 0,
            paddingRight: 0,
            '&::before': {
                position: 'absolute',
                top: 'calc(100% - 6px)',
                left: '50%',
                right: '50%',
                bottom: 6,
                display: 'block',
                content: '""',
                backgroundColor: 'transparent',
                borderRadius: theme.border.radius,
                transition: 'all .3s ease-in-out',
                zIndex: 0,
            },
            '&:hover, &:focus': {
                '&::before': {
                    left: 0,
                    right: 0,
                    bottom: 4,
                    backgroundColor: theme.palette.accent.main,
                }
            }
        }
    }
})