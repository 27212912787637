import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import Link from "../../Link"
import styles from "./styles"

import gaLogo from "../../../images/footer-ga-logo.png"

const useStyles = makeStyles(theme => styles(theme))

export default function AAGFooter() {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query AAGFooter {
      wp {
        clientOptions {
          ClientOptions {
            aagFooterLogo {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 475, formats: [WEBP], quality: 90)
                }
              }
            }
          }
        }
      }
    }
  `)

  let image = null

  let logo = data.wp.clientOptions.ClientOptions.aagFooterLogo

  if (logo.localFile) {
    if (logo.localFile.childImageSharp) {
      image = (
        <img
          src={
            logo.localFile.childImageSharp.gatsbyImageData.images.fallback.src
          }
          width="475"
          height={logo.localFile.childImageSharp.gatsbyImageData.height}
          alt="Home"
        />
      )
    } else {
      // Gifs do not have a childImageSharp
      image = <img src={logo.localFile.publicURL} alt="Home" width="475" />
    }
  } else {
    // This is a preview image
    image = <img src={logo.sourceUrl} alt="Home" width="475" />
  }

  return (
    <div className={classes.aagFooter}>
      <div className="wrapper">
        <Link
          to="https://groupauto.co.uk/"
          className="ga-logo"
          alt="Group Auto"
        >
          <img
            src={gaLogo}
            alt="Group Auto UK & Ireland"
            width="245"
            height="70"
          />
        </Link>

        {/* <Link to="https://groupauto.co.uk/" className="ga-member-logo" alt="Group Auto Logo">
          {image}
        </Link> */}
      </div>
    </div>
  )
}
