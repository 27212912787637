const trackEvent = (event) => {
    if (typeof window !== `undefined`) {
        let data = window.dataLayer;
        if (data) data.push(event);
    }
};

export const trackOutboundLink = (url) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'outbound-link',
        customAction: 'click',
        customLabel: url,
        customValue: ''
    });
};

export const trackFormSubmit = (formTitle) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'form-submit',
        customAction: 'click',
        customLabel: formTitle,
        customValue: ''
    });
};

export const trackAdvertLinkClick = (advertUrl) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'advert-link-click',
        customAction: 'click',
        customLabel: advertUrl,
        customValue: ''
    });
};

export const trackEmailLinkClick = (emailAddress) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'email-link-click',
        customAction: 'click',
        customLabel: emailAddress,
        customValue: ''
    });
};

export const trackPhoneLinkClick = (phoneNumber) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'phone-link-click',
        customAction: 'click',
        customLabel: phoneNumber,
        customValue: ''
    });
};

export const trackWhatsAppLinkClick = (path) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'whatsapp-link-click',
        customAction: 'click',
        customLabel: path,
        customValue: ''
    });
};


export const trackSocialLinkClick = (socialUrl) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'social-link-click',
        customAction: 'click',
        customLabel: socialUrl,
        customValue: ''
    });
};

export const trackSocialShare = (medium) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'social-share',
        customAction: 'click',
        customLabel: 'shared to: ' + medium,
        customValue: ''
    });
};
