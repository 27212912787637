export default theme => ({ // eslint-disable-line
  alertModal: {
    "& .alert-modal": {
      border: "none !important",
      outline: "none !important",
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      position: "absolute",
      top: 100,
      left: "50%",
      transform: "translateX(-50%)",
      padding: "25px 50px",
      width: "calc(100vw - 30px)",
      maxWidth: 565,
      [theme.breakpoints.up("md")]: {
        top: 200,
        padding: "40px 90px",
      },
      "& .bullhorn": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 30,
        "&.primary": {
          "&::before, &::after": {
            backgroundColor: theme.palette.primary.main,
          },
        },
        "&.on-primary": {
          "&::before, &::after": {
            backgroundColor: theme.palette.primary.contrastText,
          },
        },
        "&.primary-highlight": {
          "&::before, &::after": {
            backgroundColor: theme.palette.primary.highlight,
          },
        },
        "&.secondary": {
          "&::before, &::after": {
            backgroundColor: theme.palette.secondary.main,
          },
        },
        "&.on-secondary": {
          "&::before, &::after": {
            backgroundColor: theme.palette.secondary.contrastText,
          },
        },
        "&.secondary-highlight": {
          "&::before, &::after": {
            backgroundColor: theme.palette.secondary.highlight,
          },
        },
        "&.tertiary": {
          "&::before, &::after": {
            backgroundColor: theme.palette.tertiary.main,
          },
        },
        "&.on-tertiary": {
          "&::before, &::after": {
            backgroundColor: theme.palette.tertiary.contrastText,
          },
        },
        "&.tertiary-highlight": {
          "&::before, &::after": {
            backgroundColor: theme.palette.tertiary.highlight,
          },
        },
        "&.accent": {
          "&::before, &::after": {
            backgroundColor: theme.palette.accent.main,
          },
        },
        "&.on-accent": {
          "&::before, &::after": {
            backgroundColor: theme.palette.accent.contrastText,
          },
        },
        "&.accent-highlight": {
          "&::before, &::after": {
            backgroundColor: theme.palette.accent.highlight,
          },
        },
        "&::before, &::after": {
          height: 1,
          content: "''",
          display: "block",
          flex: "0 0 calc(50% - 31px)",
        },
        "& svg": {
          margin: "0 20px",
        },
      },
      "& p, & li": {
        lineHeight: 1.5,
        "& a": {},
      },
      "& button": {
        color: theme.palette.secondary.contrastText,
        fontSize: 16,
        letterSpacing: "0.016em",
        lineHeight: 1.5,
        margin: "35px auto 0",
        display: "block",
        verticalAlign: "middle",
        backgroundColor: "transparent",
        border: "none",
        fontFamily: theme.headerFont,
        textTransform: "uppercase",
        "&:hover": {
          cursor: "pointer",
        },
        "& span": {
          backgroundColor: theme.palette.secondary.contrastText,
          width: 18,
          height: 18,
          clipPath:
            "polygon(7px 1px, 8px 0px, 10px 0px, 11px 1px, 11px 7px, 17px 7px, 18px 8px, 18px 10px, 17px 11px, 11px 11px, 11px 17px, 10px 18px, 8px 18px, 7px 17px, 7px 11px, 1px 11px, 0px 10px, 0px 8px, 1px 7px, 7px 7px)",
          display: "inline-block",
          verticalAlign: "text-top",
          transform: "rotate(45deg)",
          marginLeft: 3,
        },
      },
    },
  },
})
