import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import ReactHtmlParser from "react-html-parser"
import Link from "../../Link"
import styles from "./styles"
import Icon from "@material-ui/core/Icon"

const useStyles = makeStyles(theme => styles(theme))

export const AcfButtonBlock = props => {
  // console.log(props)

  const classes = useStyles()

  if (props.block.ButtonFields.buttonLink === null) return null

  return (
    <Link
      className={` button ${classes.button} ${props.block.ButtonFields.buttonStyle} ${props.attributes.className}`}
      to={props.block.ButtonFields.buttonLink?.url.replace(
        process.env.GATSBY_FRONTEND_URL,
        ""
      )}
      //  target={props.block.ButtonFields.buttonLink?.target}
    >
      {props.block.ButtonFields.showIcon === "left" && (
        <Icon style={{ marginRight: 8 }}>{props.block.ButtonFields.icon}</Icon>
      )}
      <span>{ReactHtmlParser(props.block.ButtonFields.buttonLink.title)}</span>
      {props.block.ButtonFields.showIcon === "right" && (
        <Icon style={{ marginLeft: 8 }}>{props.block.ButtonFields.icon}</Icon>
      )}
    </Link>
  )
}

AcfButtonBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfButtonBlockFragment = graphql`
  fragment AcfButtonBlock on WpBlock {
    ... on WpAcfButtonBlock {
      name
      attributes {
        className
      }
      ButtonFields {
        buttonStyle
        buttonLink {
          url
          title
          target
        }
        icon
        showIcon
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfButtonBlockPreview = `
... on AcfButtonBlock {
  name
  attributes {
    className
  }
  ButtonFields {
    buttonStyle
    buttonLink {
      url
      title
      target
    }
    icon
    showIcon
  }
}
`
