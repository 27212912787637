const styles = theme => ({
  aagHeader: {
    backgroundColor: "#D0CFD2",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",

    "& .ga-byline": {
      textTransform: "uppercase",
    },

    // [theme.breakpoints.up(435)]: {
    //   justifyContent: "space-between",
    // },
    // "& .ga-logo": {
    //   flex: "0 0 160px",
    //   maxWidth: 160,
    //   marginBottom: 10,
    //   [theme.breakpoints.up(435)]: {
    //     marginBottom: 0,
    //   },
    // },
    // "& .ga-member": {
    //   flex: "0 0 230px",
    //   maxWidth: 230,
    // },
  },
})
export default styles
