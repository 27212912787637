import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Link from '../../Link';
import ReactHtmlParser from "react-html-parser";
import styles from './styles';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles(theme => styles(theme));

export default function Footer001(props) {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query Footer001Items {
      allWpMenu(filter: {locations: {in: FOOTER_MENU}}) {
        edges {
          node {
            id
            name
            slug
            menuItems {
              nodes {
                id
                title
                url
                path
                label
                target
                order
                childItems {
                  nodes {
                    id
                    title
                    url
                    path
                    label
                    target
                    order
                  }
                }
              }
            }
          }
        }
      }
      wp {
        allSettings {
          generalSettingsDescription
        }
        businessInformation {
          LocationData {
            address1
            address2
            email
            name
            postcode
            telephone
            region
            town
          }
        }
        themeOptions {
          ThemeOptions {
            facebookUrl
            twitterHandle
            instagramHandle
            linkedinUrl            
            footerLogo {
              sourceUrl
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 175,
                    formats: [WEBP],,
                    quality: 90
                  )
                }
              }
            }
            footerOverrides {
              footerBackgroundColour
              footerTextColour
              footerLinkColour
              footerHeaderColour
              footerHBorderColour
              footerIconColour
              footerStraplineColour
              footerLineColour
            }
          }
        }
      }
    }
  `);
  let locData = data.wp.businessInformation.LocationData;
  let social = data.wp.themeOptions.ThemeOptions;
  let logo = data.wp.themeOptions.ThemeOptions.footerLogo;
  let overrides = data.wp.themeOptions.ThemeOptions.footerOverrides;

  let image = null;

  // Use the gatsby image if available
  if (logo.localFile) {
    if (logo.localFile.childImageSharp) {
      image = <img
        src={logo.localFile.childImageSharp.gatsbyImageData.images.fallback.src}
        width="175"
        height={logo.localFile.childImageSharp.gatsbyImageData.height}
        alt="Home"
      />
    } else {
      // Gifs do not have a childImageSharp
      image = <img
        src={logo.localFile.publicURL}
        width="175"
        alt="Home"
      />
    }
  } else {
    // This is a preview image
    image = <img
      src={logo.sourceUrl}
      width="175"
      alt="Home"
    />
  }

  return (
    <div className={classes.footer001}
      data-override-background={overrides.footerBackgroundColour !== 'primary' ? overrides.footerBackgroundColour : null}
    >

      <div className="wrapper">

        <div className="site-logo">
          <Link to="/">
            {image}
          </Link>
        </div>

        <div className="footer-columns">

          <div className="col contact">

            <p className="footer-h"
              data-override-color={overrides.footerHeaderColour !== 'on-primary' ? overrides.footerHeaderColour : null}
              data-override-border={overrides.footerHBorderColour !== 'on-primary' ? overrides.footerHeaderColour : null}
            >Contact Us</p>
            <p data-override-color={overrides.footerTextColour !== 'on-primary' ? overrides.footerTextColour : null}>
              {locData.address1 !== null &&
                <span>{locData.address1}, <br /></span>
              }
              {locData.address2 !== null &&
                <span>{locData.address2}, <br /></span>
              }
              {locData.town !== null &&
                <span>{locData.town}, <br /></span>
              }
              {locData.region !== null &&
                <span>{locData.region}, <br /></span>
              }
              {locData.postcode !== null &&
                <span>{locData.postcode} </span>
              }
            </p>
            <Link to={'tel:' + locData.telephone}
              data-override-color={overrides.footerLinkColour !== 'on-primary' ? overrides.footerLinkColour : null}
            >{locData.telephone}</Link>
            <Link to={'mailto:' + locData.email}
              data-override-color={overrides.footerLinkColour !== 'on-primary' ? overrides.footerLinkColour : null}
            >{locData.email}</Link>

          </div>

          <div className="col nav">

            <p className="footer-h"
              data-override-color={overrides.footerHeaderColour !== 'on-primary' ? overrides.footerHeaderColour : null}
              data-override-border={overrides.footerHBorderColour !== 'on-primary' ? overrides.footerHeaderColour : null}
            >{data.allWpMenu.edges[0].node.name}</p>
            <div className="footer-nav">
              {data.allWpMenu.edges[0].node.menuItems.nodes.map(section => (
                <Link
                  key={section.id}
                  to={section.path}
                  data-override-color={overrides.footerLinkColour !== 'on-primary' ? overrides.footerLinkColour : null}
                >
                  {ReactHtmlParser(section.label)}
                </Link>
              ))}
            </div>

          </div>

          <div className="col social">

            <p className="footer-h"
              data-override-color={overrides.footerHeaderColour !== 'on-primary' ? overrides.footerHeaderColour : null}
              data-override-border={overrides.footerHBorderColour !== 'on-primary' ? overrides.footerHeaderColour : null}
            >Follow us</p>
            {(social.facebookUrl !== null && social.facebookUrl !== "") &&
              <Link className="social-link"
                to={social.facebookUrl}
                data-override-color={overrides.footerLinkColour !== 'on-primary' ? overrides.footerLinkColour : null}
              >
                <FacebookIcon data-override-color={overrides.footerIconColour !== 'on-primary' ? overrides.footerIconColour : null} />
                <span>Facebook</span>
              </Link>
            }
            {(social.twitterHandle !== null && social.twitterHandle !== "") &&
              <Link className="social-link"
                to={social.twitterHandle}
                data-override-color={overrides.footerLinkColour !== 'on-primary' ? overrides.footerLinkColour : null}
              >
                <TwitterIcon data-override-color={overrides.footerIconColour !== 'on-primary' ? overrides.footerIconColour : null} />
                <span>Twitter</span>
              </Link>
            }
            {(social.instagramHandle !== null && social.instagramHandle !== "") &&
              <Link className="social-link"
                to={social.instagramHandle}
                data-override-color={overrides.footerLinkColour !== 'on-primary' ? overrides.footerLinkColour : null}
              >
                <InstagramIcon data-override-color={overrides.footerIconColour !== 'on-primary' ? overrides.footerIconColour : null} />
                <span>Instagram</span>
              </Link>
            }
            {(social.linkedinUrl !== null && social.linkedinUrl !== "") &&
              <Link className="social-link"
                to={social.linkedinUrl}
                data-override-color={overrides.footerLinkColour !== 'on-primary' ? overrides.footerLinkColour : null}
              >
                <LinkedInIcon data-override-color={overrides.footerIconColour !== 'on-primary' ? overrides.footerIconColour : null} />
                <span>LinkedIn</span>
              </Link>
            }

          </div>

        </div>

        <p className="strapline"
          data-override-color={overrides.footerStraplineColour !== 'on-primary' ? overrides.footerStraplineColour : null}
        >{ReactHtmlParser(data.wp.allSettings.generalSettingsDescription)}</p>

        <div className="footer-bottom"
          data-override-border={overrides.footerHBorderColour !== 'on-primary' ? overrides.footerHeaderColour : null}
        >

          <p className="copyright"
            data-override-color={overrides.footerTextColour !== 'on-primary' ? overrides.footerTextColour : null}
          >© Copyright Secure. All rights reserved.</p>
          <p className="credit"
            data-override-color={overrides.footerTextColour !== 'on-primary' ? overrides.footerTextColour : null}
          >Engineered by <Link to="https://www.wearebrew.co.uk">brew</Link></p>

        </div>

      </div>

    </div>
  );
}