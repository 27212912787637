import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import Link from "../../Link"
import ReactHtmlParser from "react-html-parser"
import styles from "./styles"
// import { GatsbyImage } from "gatsby-plugin-image";
import { AcfButtonBlock } from "../../blocks/AcfButtonBlock"

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"

const useStyles = makeStyles(theme => styles(theme))

export default function Header001(props) {
  const [open, setOpen] = React.useState(false)
  const [openSub, setOpenSub] = React.useState("")

  const handleOpen = () => {
    setOpen(!open)
  }

  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query Header001Items {
      allWpMenu(filter: { locations: { in: HEADER_MENU } }) {
        edges {
          node {
            id
            name
            slug
            menuItems {
              nodes {
                id
                title
                url
                path
                label
                target
                order
                parentId
                childItems {
                  nodes {
                    id
                    title
                    url
                    path
                    label
                    target
                    order
                  }
                }
              }
            }
          }
        }
      }
      wp {
        themeOptions {
          ThemeOptions {
            siteLogo {
              sourceUrl
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 235, formats: [WEBP])
                }
              }
            }
            headerOverrides {
              headerBackgroundColour
              headerLinkColour
              dropdownBackgroundColour
              dropdownLinkColour
            }
            tradeLoginButton
            tradeLoginUrl
          }
        }
      }
    }
  `)

  let logo = data.wp.themeOptions.ThemeOptions.siteLogo
  let overrides = data.wp.themeOptions.ThemeOptions.headerOverrides

  let image = null

  // console.log(logo);

  // Use the gatsby image if available
  if (logo.localFile) {
    if (logo.localFile.childImageSharp) {
      image = image = (
        <img
          src={
            logo.localFile.childImageSharp.gatsbyImageData.images.fallback.src
          }
          width="235"
          height={logo.localFile.childImageSharp.gatsbyImageData.height}
          alt="Home"
        />
      )
    } else {
      // Gifs do not have a childImageSharp
      image = <img src={logo.localFile.publicURL} width="235" alt="Home" />
    }
  } else {
    // This is a preview image
    image = <img src={logo.sourceUrl} width="235" alt="Home" />
  }

  return (
    <div
      className={classes.header001}
      data-override-background={
        overrides.headerBackgroundColour !== "primary"
          ? overrides.headerBackgroundColour
          : null
      }
      open={open}
    >
      <div className="wrapper">
        {data.wp.themeOptions.ThemeOptions.tradeLoginButton && (
          <div className="trade-login">
            <AcfButtonBlock
              attributes={{
                className: "",
              }}
              block={{
                ButtonFields: {
                  buttonLink: {
                    target: "_blank",
                    title: "Trade Login",
                    url: data.wp.themeOptions.ThemeOptions.tradeLoginUrl,
                  },
                  buttonStyle: "primary",
                  icon: "person",
                  showIcon: "left",
                },
              }}
              innerBlocks={[]}
            />
          </div>
        )}

        <div className="site-logo">
          <Link to="/">{image}</Link>
        </div>

        <button
          className={`menu-trigger ${
            overrides.headerLinkColour !== "on-secondary"
              ? overrides.headerLinkColour
              : ""
          } `}
          onClick={handleOpen}
          onKeyDown={handleOpen}
          aria-label="Open Menu"
        >
          <span />
        </button>

        <div className="site-nav">
          {data.allWpMenu.edges[0].node.menuItems.nodes.map(section => {
            // console.log(section);
            if (section.childItems.nodes.length > 0) {
              return (
                <div
                  className="sub-menu"
                  key={section.id}
                  data-active={openSub === section.id ? true : false}
                >
                  <button
                    onClick={() => {
                      if (openSub === section.id) {
                        setOpenSub("")
                      } else {
                        setOpenSub(section.id)
                      }
                    }}
                    onKeyDown={() => setOpenSub(section.id)}
                    data-override-color={
                      overrides.headerLinkColour !== "on-primary"
                        ? overrides.headerLinkColour
                        : null
                    }
                  >
                    {ReactHtmlParser(section.label)} <ArrowDropDownIcon />
                  </button>
                  <div
                    className="sub-items-wrap"
                    data-override-background={
                      overrides.dropdownBackgroundColour !== "on-primary"
                        ? overrides.dropdownBackgroundColour
                        : null
                    }
                  >
                    <div className="sub-items">
                      {section.childItems.nodes.map(child => {
                        return (
                          <Link
                            key={child.id}
                            to={child.path}
                            data-override-color={
                              overrides.dropdownLinkColour !== "primary"
                                ? overrides.dropdownLinkColour
                                : null
                            }
                          >
                            {ReactHtmlParser(child.label)}
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            } else if (section.parentId == null) {
              return (
                <Link
                  key={section.id}
                  to={section.path}
                  data-override-color={
                    overrides.headerLinkColour !== "on-primary"
                      ? overrides.headerLinkColour
                      : null
                  }
                >
                  {ReactHtmlParser(section.label)}
                </Link>
              )
            }
            return null
          })}
        </div>
      </div>
    </div>
  )
}
