const styles = theme => ({
  aagFooter: {
    backgroundColor: "white",
    borderTop: "4px solid #FFFF00",
    "& .wrapper": {
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.up(435)]: {
        justifyContent: "flex-start",
        padding: 0,
      },
      "& .ga-logo": {
        flex: "0 0 100px",
        maxWidth: 100,
        marginBottom: 10,
        margin: 12,
      },
      "& .ga-member-logo": {
        flex: "0 0 275px",
        maxWidth: 275,
        margin: 12,
      },
    },
  },
})
export default styles
