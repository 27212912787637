import React from 'react';
import { Link as GatsbyLink } from "gatsby";
import { trackOutboundLink, trackPhoneLinkClick, trackEmailLinkClick, trackSocialLinkClick, trackWhatsAppLinkClick } from '../../services/gtm'

const Link = ({ children, to, activeClassName, className, forceBlank, ...other }) => {

    if (typeof (to) === "string" && !to.includes('.pdf')) {
        if (to) to = to.replace(process.env.GATSBY_BACKEND_URL, '');
        const internal = /^\/(?!\/)/.test(to);

        if (internal && forceBlank !== true) {
            // add a trailing slash
            // If there isnt one already and there isnt a hash present
            if (to.substr(-1) !== '/' && to.includes('#') === false) to += '/';
            return (
                <GatsbyLink to={to} activeClassName={activeClassName} className={className} {...other}>
                    {children}
                </GatsbyLink>
            )
        }
    }

    if (className && className.includes('social-link')) {
        return (
            <a href={to} {...other} className={className} target="_blank" rel="noopener noreferrer" onClick={() => trackSocialLinkClick(to)} >
                {children}
            </a>
        )
    }
    return (
        <a href={to} {...other} className={className} target="_blank" rel="noopener noreferrer" onClick={() => {
            switch (to.split(':')[0]) {
                case 'tel':
                    trackPhoneLinkClick(to.split(':')[1]);
                    break;
                case 'email':
                    trackEmailLinkClick(to.split(':')[1]);
                    break;
                case 'whatsapp':
                    trackWhatsAppLinkClick(to.split(':')[1]);
                    break;
                default:
                    trackOutboundLink(to)
            }
        }
        } >
            {children}
        </a>
    )
};

export default Link
