const styles = theme => ({
  uanHeader: {
    backgroundColor: "white",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",

    "& .uan-byline": {
      textTransform: "uppercase",
    },

    // [theme.breakpoints.up(435)]: {
    //     justifyContent: 'space-between',
    // },
    // '& .ga-logo': {
    //     flex: '0 0 100px',
    //     maxWidth: 100,
    //     marginBottom: 10,
    //     [theme.breakpoints.up(435)]: {
    //         marginBottom: 0,
    //     },
    // },
    // '& .ga-member': {
    //     flex: '0 0 231px',
    //     maxWidth: 231,
    //     [theme.breakpoints.up('md')]: {
    //         flex: '0 0 360px',
    //         maxWidth: 360,
    //     }
    // }
  },
})
export default styles
